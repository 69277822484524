<template>
  <div>
    <!-- SECTION 1 -->
    <section class="section">
      <CContainer class="">
        <CRow>
          <CCol sm="12" class="text-center block-header">
            <h2>Our Articles</h2>
            <p class="text-muted">Read up on what we've posted.</p>
            <CButton
              class="custom-btn outline"
              type="submit"
              @click="$router.push({ path: '/contact' })"
            >
              Contact Us
            </CButton>
            <br />
          </CCol>

          <CCol sm="4" class="article-block" v-for="article in articles">
            <CImg
              v-if="article.image"
              :src="storageURL + article.image"
              block
              class="mb-2"
            />
            <CImg
              v-else
              src="/img/frontpage/image-placeholder.jpg"
              block
              class="mb-2"
            />
            <h3>{{ article.title }}</h3>
            <p>{{ article.excerpt }}</p>
            <CLink :href="'/articles/' + article.id" class="custom-link">
              Read More <CIcon name="cil-arrow-right" />
            </CLink>
          </CCol>
        </CRow>
      </CContainer>
    </section>
  </div>
</template>

<script>
import ArticleAPI from "/app/src/api/article.js";

export default {
  name: "Cases",
  data: () => {
    return {
      articleAPI: new ArticleAPI(),
      articles: [],
      storageURL: process.env.VUE_APP_STORAGE_URL,
    };
  },

  created: function () {
    console.log(this.$route.name);
    this.articleAPI
      .all()
      .then((articles) => {
        this.articles = articles;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {},
};
</script>

<style scoped>
.article-block {
  margin-top: 30px;
  margin-bottom: 30px;
}

.article-block h3 {
  font-weight: 700;
}

.article-block p {
  font-size: 14px;
}

.article-block img {
  border-radius: 6px;
  width: 100%;
  height: 250px;
  object-fit: cover;
}
</style>
